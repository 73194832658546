import React from 'react';

export function HouseGuide() {
    return (
        <div className="HouseGuide-container">
            <br/>
            <h1>House Guide</h1>
            <br/>
            <p>To come soon!</p>
        </div>
    )
}