import React from 'react';

export function Directions() {
    return (
        <div className="Directions-container">
            <br/>
            <h1>Directions</h1>
            <br/>
            <p>To come soon!</p>
        </div>
    )
}