import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import './PasswordReset.css';
//import ReCAPTCHA from 'react-google-recaptcha';

export function PasswordReset() {

    //modal functionality
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // State prep
    const [author, setAuthor] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    //const [validCaptcha, setCaptcha] = useState(false);
    //const recaptchaRef = React.createRef();

    //Prepare user list
    const [users, setUsers] = useState([]);
    //Effect hook will throw error for missing the users variable in dependency array, but this is an es-lint error for standard react code not a malfunctioning Effect hook
    useEffect(() => {
        FetchUsers();
        setValidEmail(users.some(user => user.email === email));
    }, [email]);
    
    //Data fetching
    const FetchUsers = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_SRVR_URL}/users/all`)
            setUsers(res.data);
        } catch (error) {
            console.log('There was an error while loading the user data, form validation may be affected.')
        }
    }

    // Form reset
    const handlePwResetFormReset = () => {
        setAuthor('');
        setEmail('');
        setValidEmail(false);
    }

    const closeResetButton = () => {
        handleClose();
        handlePwResetFormReset();
    }

    //Axios POST for sending email form
    const handlePwResetRequest = () => {
        try {
            //Send POST req to backend
            axios
                .post(`${process.env.REACT_APP_SRVR_URL}/pwreset/email`, {
                    author: author,
                    email: email
                })
                .then(res => {
                    console.log(res.data);
                    
                })
                .catch(error => {
                    alert(`There was an error sending the message`);
                    console.error(`There was an error sending the reset request email: ${error}`)
                });
            console.log('sending password reset request');
            axios
                .post(`${process.env.REACT_APP_SRVR_URL}/pwreset/pwReset`, {
                    email: email
                })
                .then(res => {
                    console.log(res.data)
                })
                .catch(error => {
                    console.error(`There was an error resetting the password: ${error}`);
                    alert(`There was an error resetting the password: ${error} --- No changes have been made Please contact support`);
                });
            alert(`Password Reset successful. Please check your email.`);
        } catch (error) {
            console.log('There was an error while resetting the password.');
            alert(`There was an error resetting the password: ${error} --- Please contact support`);
        }
    };

    const handleResetSubmit = () => {
        if (validEmail) {
            handlePwResetRequest()
            console.log("validEmail")
            alert(`Please go to your email's inbox for a temporary password that you can use until you login and reset your password by --- Logging in > Click "Profile" > Click "Edit" > and update/confirm your password.`);
            closeResetButton();
        } else {
            console.log("False validEmail")
            alert(`Sorry, I don't see an account with that email. It needs to be the one you set up your account with. If you know it to be the one you used to setup your account, please contact me for support!`);
        }
    };


    return(
        <div className="PasswordReset-container">
            <Button className="passwordResetButton" onClick={handleShow} variant="danger">
                Password Reset
            </Button>

            <Modal show={show} onHide={closeResetButton}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Your Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form autoComplete="off">
                        <Form.Group className="resetPw-verification">
                            <Form.Label>What is the email to your account?</Form.Label>
                            <Form.Control
                                value={email}
                                type="text"
                                placeholder="email@domain.com"
                                onChange={(e) => {
                                    setEmail(e.currentTarget.value);
                                }}
                            />
                        </Form.Group>
                        <br />
                        <Button 
                            onClick={handleResetSubmit} 
                            variant={!validEmail ? "outline-danger" : "success"} 
                            className="reset-password-button"
                            disabled={!validEmail}
                        >
                            Reset
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}