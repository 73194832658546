import React from 'react';

export function ForgotDoc() {
    return (
        <div className="HouseGuide-container">
            <br/>
            <h1>I forgot my password, now what?</h1>
            <br/>
            <p>Click/Tap on the <strong>"Password Reset"</strong> button below the Login page.</p>
            <p>The windows will ask you 2 questions that are in important:</p>
            <ol>
                <li>What is your email that you used to make your account?</li>
                <li>Are you sure you want to reset this password?</li>
            </ol>
            <p>An email will then be sent to the email you entered. Make sure it is the one on your account, or else it won't send you a temporary password to login with.</p>
            <p>So that no one can steal your password, a new, temporary, password will be emailed to you and you will use that to login.</p>
            <p>Then, once you are able to login - go to your Profile tab and "Edit Profile" where you may change your password to something you choose!</p>
        </div>
    )
}