import React from 'react';

export function Welcome() {
    return (
        <div className="Welcome-container">
            <br/>
            <h1>Hello there!</h1>
            <h5>Welcome to the new Cedar Falls web application!</h5>
            <p>Here's a few quick ways to interact with it--</p>
            <h4>RESERVATION</h4>
            <p>The big calendar on your screen shows the reservations that have been made.</p>
            <p>It is interactive, but to add your own reservation click on the Reservation button.</p>
            <p>The only parts you have to put are the <strong>title</strong> and the <strong>dates</strong>,
            but I also added a system that can reserve some of the rooms at the ranch house and barn loft.</p>
            <p>
                This is still an early version of the reservation system,
                but for now the dates and title are what create the reservation object on the calendar for others to share in seeing!
                To reserve a room for the reservation dates, flip the switch to on under at the bottom of the form below <i>Staying the night at Cedar Falls Ranch?</i>
                Then you may select the room/rooms you want to call your dibs on!
                With the space being so new, I don't have an accurate way to identify which rooms are which.
                So, maybe we can come up with names for the different rooms? Send me suggestions! :D
            </p>
            <p><i>Any dibs disputes must be settled by paper-rock-scissors. Best 2/3, throw on <u>shoot</u>.</i></p>
            <p>If you want to edit or delete your reservation, go to <strong>Profile</strong>.</p>
            <h4>PROFILE</h4>
            <p>Don't mind the picture, early version remember?</p>
            <p>Your profile is useful for 2 things: editing your reservations and changing what they look like!</p>
            <p>
                All your reservations are shown on the calendar at the bottom of the screen.
                Click/Tap on them to edit them - if you want to <strong>delete</strong> it, there is a button for that at the bottom.
                You can change your room and dates too, if that room is still available for the dates selected.
            </p>
            <p>
                I also thought it'd be nice to have a color to associate your reservations with, so you can choose what they look like if you want.
                Select <strong>Edit Profile</strong> above the cowboy to choose new colors as well as your <i>email, phone, </i>and<i> <strong>password</strong></i>.
                An example of what it will look like is shown on your Profile tab!
            </p>
            <p>
                Sorry, but you can't change your name because it's your username for your account! 
                If you make a mistake when you made your account, 
                send a request to me through the contact form and I'll make the change from the admin side.
            </p>
            <p>
                To <strong>change your password</strong>, you will do so while Editing your Profile. You do not have to update your email and phone when you change your password, 
                each part of the profile editing form updates each aspect independently. 
            </p>
            <h4>Message Board</h4>
            <p>It's not much, but a simple message board to make announcements and ask questions.</p>
            <p></p>
        </div>
    )
}